import React, { useContext, useState, useEffect, useRef } from 'react';
import { get } from "lodash";
import { Link } from 'gatsby';
import BlogListsContext from '../context/BlogListsProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from "../components/organisms/Seo/Seo";
import Container from '../components/atoms/Container/Container';
// import Accordion from '../components/molecules/Accordion/Accordion';
// import Tabs from '../components/molecules/Tabs/Tabs';
import PostListContentful from '../components/organisms/PostListContentful/PostListContentful';
import { handleLink } from '../helpers/general';
import * as styles from './blog.module.css';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import ContentfulContext from '../context/ContentfulProvider';

const BlogContentful = () => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const blogListsContext = useContext(BlogListsContext);
    const [title, setTitle] = useState('news');
    const [blog, setBlog] = useState([]);
    const [ftBlogDesc, setFtBlogDesc] = useState([]);
    const sliderAnchor = useRef();
    const containerAnchor = useRef();
    const menuItems = [
        { label: get(translations, 'news.title', 'NEWS'), itemKey: 'news' }
    ];
    
    const handleClickOnTab = (e) => {
        setTitle(e);
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const activeTab = window.location.hash;
            if (activeTab) {
                let clean = activeTab.replace('#', '').toLowerCase();
                if (['news'].includes(clean)) {
                    handleClickOnTab(clean);
                }
            }
        }
    }, []);

    useEffect(() => {
        setBlog(blogListsContext[title].slice(1));

        if (blogListsContext && blogListsContext[title][0]) {
            const parsedDesc = JSON.parse(blogListsContext[title][0].node.blogContent.raw).content[0].content[0];
            setFtBlogDesc(parsedDesc ? parsedDesc.value : null)
        }

        let breadcrumbData = [];
        breadcrumbData.push({link: '/', label: get(translations, 'home.title', 'Home')});
        breadcrumbData.push({label: title === 'news' ? get(translations, 'news.title', 'News') : get(translations, 'news.title', 'News')});
        setBreadcrumbs(breadcrumbData);
    }, [title, blogListsContext, setBreadcrumbs, translations]);

    // console.log(blogListsContext);

    return (
        <Layout>
            <Seo title="Blog" />
            <Container size="large">
                <Breadcrumbs crumbs={breadcrumbs} />
                {blogListsContext !== undefined && blogListsContext[title].length > 0 && (
                    <div className={styles.blogFeatured}>
                        <div className={styles.blogFeaturedCol6}>
                            <Link to={handleLink(`/blog/${blogListsContext[title][0].node.slug}/`)}>
                                <img src={blogListsContext[title][0].node.blogFeaturedImage.file.url} alt={blogListsContext[title][0].node.blogTitle} />
                            </Link>
                        </div>
                        <div className={styles.blogFeaturedCol3}>
                            <div className={`h6`}><Link to={handleLink(`/blog/${blogListsContext[title][0].node.slug}/`)}>{blogListsContext[title][0].node.blogTitle}</Link></div>
                            <div className={styles.blogListDesc}>{ftBlogDesc}</div>
                            <div className={styles.blogListDate}>{blogListsContext[title][0].node.updatedAt}</div>
                        </div>
                    </div>
                )}
                <div className={styles.root} ref={containerAnchor}>
                    <div ref={sliderAnchor} data-active={title}>
                        <ul className={styles.customTab}>
                            {menuItems && menuItems.map((item, index) => (
                                <li key={index} role="presentation" onClick={(e) => handleClickOnTab(item.itemKey)}>{item.label}</li>
                            ))}
                        </ul>
                        {/* <Tabs 
                            color={'#00A2DA'} 
                            mode={'full'} 
                            align='left' 
                            items={menuItems} 
                            onChangeTab={(e) => handleClickOnTab(e)}
                            active={title}
                        /> */}
                        {blogListsContext !== undefined && blogListsContext[title].length > 1 ? (
                            <div>
                                <PostListContentful
                                    posts = {blog}
                                    viewMode = {'grid'}
                                    cardSize = {'large'}
                                    titleOnly = {false}
                                />
                            </div>
                        ) : (
                            <h6>No articles found for {title === 'news' ? 'News' : 'Events'}</h6>
                        )}
                    </div>
                </div>
            </Container>
        </Layout>
    );
}

export default BlogContentful;
