// extracted by mini-css-extract-plugin
export var breadCrumb = "blog-module--breadCrumb--dGd3F";
export var blogFeatured = "blog-module--blogFeatured--0Exn9";
export var blogFeaturedCol6 = "blog-module--blogFeaturedCol6--PcnjN";
export var blogFeaturedCol3 = "blog-module--blogFeaturedCol3--40boX";
export var h6 = "blog-module--h6--7Ke5D";
export var blogListDesc = "blog-module--blogListDesc--gZ5S4";
export var blogListDate = "blog-module--blogListDate--or1DD";
export var root = "blog-module--root--gVuY+";
export var customTab = "blog-module--customTab--FUYih";
export var content = "blog-module--content--XVLCL";
export var mobileContainer = "blog-module--mobileContainer--oYDhA";
export var section = "blog-module--section--VUlWY";
export var hide = "blog-module--hide--k14Rr";